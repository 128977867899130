import { FC } from 'react'
import styled, { css } from 'styled-components'

interface IFSkeletonProps {
  width?: number | string
  height?: number | string
  className?: string
}

const Skeleton: FC<IFSkeletonProps> = ({
  width = 'auto',
  height = 16,
  className,
}) => {
  return <StyledWrapper width={width} height={height} className={className} />
}

export default Skeleton

const StyledWrapper = styled.div<{
  width: number | string
  height: number | string
}>`
  ${({ width, height }) => css`
    width: ${typeof width === 'number' ? `${width}px` : width};
    height: ${typeof height === 'number' ? `${height}px` : height};
    background-color: rgba(0, 0, 0, 0.11);
    border-radius: 4px;
    animation: pulse 1.5s ease-in-out 0.5s infinite;

    @keyframes pulse {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0.4;
      }
      100% {
        opacity: 1;
      }
    }
  `}
`

import { FC } from 'react'
import TSvgProps from 'svgIcons/type/svgPropsType'

interface CalendarIconProps extends TSvgProps {
  isActive: boolean
}

const CalendarIcon: FC<CalendarIconProps> = ({ isActive, ...props }) => {
  return isActive ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <desc>공고달력 아이콘 활성화</desc>
      <path
        fill="#333"
        data-name="제외 8"
        d="M24122 15878h-12a3 3 0 0 1-3-3v-6.751h18v6.751a3 3 0 0 1-3 3zm3-11.248h-18v-3.748a3 3 0 0 1 3-3h12a3.007 3.007 0 0 1 3 3v3.748z"
        transform="translate(-24103.998 -15856.001)"
      />
      <path
        fill="none"
        stroke="#333"
        strokeWidth="1.5"
        data-name="선 118"
        transform="translate(8 3)"
        d="M0 0v4"
      />
      <path
        fill="none"
        stroke="#333"
        strokeWidth="1.5"
        data-name="선 119"
        transform="translate(16 3)"
        d="M0 0v4"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <desc>공고달력 아이콘 비활성화</desc>
      <g
        fill="none"
        stroke="#888"
        strokeWidth="1.5"
        data-name="사각형 6267"
        transform="translate(3 4)"
      >
        <rect width="18" height="18" stroke="none" rx="3"></rect>
        <rect width="16.5" height="16.5" x="0.75" y="0.75" rx="2.25"></rect>
      </g>
      <path
        fill="none"
        stroke="#888"
        strokeWidth="1.5"
        d="M3.5 11.5h17"
        data-name="선 117"
      ></path>
      <path
        fill="none"
        stroke="#888"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M8 3v4"
        data-name="선 118"
      ></path>
      <path
        fill="none"
        stroke="#888"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M16 3v4"
        data-name="선 119"
      ></path>
    </svg>
  )
}

export default CalendarIcon

import { ReactNode, MouseEvent } from 'react'
import styled from 'styled-components'

export interface TabBarItemProps {
  isActive: boolean
  icon: ReactNode
  label: string
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
  className?: string
}

const TabBarItem = ({
  icon,
  isActive,
  label,
  onClick,
  className,
}: TabBarItemProps) => {
  return (
    <StyledWrapper onClick={onClick} className={className}>
      <i>{icon}</i>
      <span data-is-active={isActive}>{label}</span>
    </StyledWrapper>
  )
}

export default TabBarItem

const StyledWrapper = styled.button`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: #fff;
  padding: 0;

  > span {
    font-size: 11px;
    font-weight: 500;
    line-height: 1.45;
    letter-spacing: -0.44px;
    text-align: center;
    color: #666666;

    &[data-is-active='true'] {
      font-weight: bold;
      color: #333;
    }
  }
`

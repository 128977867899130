/**
 * @desc chatRoomId 를 받아서 앱 내 채팅방으로 이동하는 다이나믹 링크를 생성합니다.
 * @param {string} chatRoomId
 * @returns {string}
 */

const getChatDynamicLink = (chatRoomId: string): string => {
  return (
    'https://linkareer.page.link/?link=' +
    encodeURIComponent(
      `https://linkareer.com/?chatOpenType=default&chatRoomId=${chatRoomId}`,
    ) +
    '&apn=com.linkareer.linkareer&ibi=com.linkareer.linkareeriOS&isi=1526086787&si=https://i.ibb.co/fq0dFBv/004-2.png&st=%20&sd=' +
    encodeURIComponent(
      '대학생 국내 1위 대외활동 공모전 인턴 신입 취업 사이트, 링커리어! 대학&취준 커뮤니티 및 합격 자기소개서•합격 후기 무료로 확인해 보세요.',
    )
  )
}

export default getChatDynamicLink
